<template>
	<div class="w-full pt-3 border-b border-gray-200">
		<div
			:class="[
				isCheckout ? 'px-4 flex-wrap md:flex pb-1.5 md:pb-0' : 'flex md:px-6',
				'px-4 md:flex xs:flex-wrap items-start w-full']"
		>
			<div class="flex ">
				<div class="w-auto mb-3 mr-4">
					<div class="w-18 md:w-24">
						<WwImage
							:src="image"
							classes="border border-gray-300 border-solid rounded-lg shadow-sm"
							:alt="product.name"
							:fallback-image="DEFAULT_LEAF_IMAGE"
							:width="70"
							:height="70"
							:image-path="MENU_IMAGES"
						/>
					</div>
				</div>
				<div class="w-auto mr-2 text-left">
					<h3 class="text-lg font-semibold capitalize">
						{{ product.name }}
					</h3>
					<div
						v-if="product.brand"
						class="text-sm font-bold text-gray-500"
					>
						{{ product.brand }}
					</div>
					<div class="flex items-center py-2 text-gray-500">
						<template v-if="product.sizeLabel">
							<span class="mr-2 text-lg font-bold text-black">
								${{ formatPrice(price) }}
							</span>
							{{ formatUnit(product.sizeLabel) }}
						</template>
						<template v-else>
							<span class="mr-2 text-lg font-bold text-black">
								${{ formatPrice(price) }}
							</span>
							Each
						</template>
					</div>
				</div>
			</div>
			<div
				:class="[
					'items-center flex-shrink-0 w-auto h-full py-2 ml-auto my-auto overflow-x-hidden xs:flex-none xs:ml-0 xs:mr-auto',
					isCheckout ? 'flex md:justify-center justify-start' : 'flex justify-center'
				]"
			>
				<div class="flex items-center justify-between w-24 text-center border border-gray-300 rounded shadow">
					<button
						:class="[
							'relative inline-flex justify-center items-center w-1/3 text-xl font-bold rounded-l cursor-arrow h-11 transition-all duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2',
							isMinimumQuantity || isUpdating ? 'bg-gray-300 cursor-not-allowed' : 'hover:shadow-lg hover:bg-red-500 hover:text-white text-black bg-white'
						]"
						:disabled="isMinimumQuantity || isUpdating"
						@click="handleUpdateQuantity('decrement')"
					>
						<MinusIcon />
					</button>
					<span
						class="relative w-1/3 font-bold text-center bg-gray-200 h-11"
						:class="{ 'text-gray-500': isUpdating }"
					>
						<span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
							{{ quantity }}
						</span>
					</span>

					<button
						:class="[
							'relative inline-flex items-center w-1/3 text-xl font-bold rounded-r cursor-arrow h-11 justify-center transition-all duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2',
							isUpdating ? 'bg-gray-300 cursor-not-allowed' : 'hover:shadow-lg hover:bg-green-500 hover:text-white text-black bg-white'
						]"
						:disabled="isUpdating"
						@click="handleUpdateQuantity('increment')"
					>
						<PlusIcon />
					</button>
				</div>
				<button
					v-if="!hideDelete"
					class="flex-none w-5 h-5 ml-4 bg-white border-none rounded md:mx-2 focus:outline-none xl:focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
					:class="[ wiggleWidIt ? 'animate-wiggle text-red-500' : 'text-gray-500' ]"
					:disabled="isUpdating"
					@click="handleRemoveFromCart"
				>
					<TrashIcon class="w-5 h-5 transition-all duration-100 ease-in-out xl:hover:text-red-500" />
				</button>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import MinusIcon from '@/components/icons/MinusIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DELETE_FROM_CART_MODAL } from '@/constants/modal/names.js'
import { DEFAULT_LEAF_IMAGE } from '@/constants/search/defaultImage.js'
import { GetCarts } from '@/gql/queries/carts.gql'
import { logError } from '@/utils/error-handling.js'
import { formatUnit } from '@/utils/pricing.js'

export default {
	components: {
		PlusIcon,
		MinusIcon,
		TrashIcon,
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		product: {
			type: Object,
			default: () => ({})
		},
		isModal: {
			type: Boolean,
			default: false
		},
		isCheckout: {
			type: Boolean,
			default: false
		},
		hideDelete: {
			type: Boolean,
			default: false
		},
		listingId: {
			type: String,
			required: true
		}
	},
	emits: [ 'removeFromCart' ],
	data() {
		return {
			quantity: this.product.quantity,
			isUpdating: false,
			DEFAULT_LEAF_IMAGE,
			wiggleWidIt: false,
			MENU_IMAGES
		}
	},
	computed: {
		cartProduct() {
			return {
				listing_id: this.listingId,
				items: [ {
					product_id: this.product.product_id || this.product.productId,
					size: parseInt(this.product.size),
					indication: this.product.indication,
					quantity: this.quantity,
					image: this.product.image,
					name: this.product.name,
					sizeLabel: this.product.sizeLabel,
					price: this.product.price
				} ]
			}
		},
		price() {
			const newPrice = this.product.price * this.product.quantity
			const roundedPrice = Math.floor(newPrice * 100) / 100 // round to 2 decimals (will cut off 0 if in hundredth position)
			const fixedPrice = roundedPrice.toFixed(2) // makes sure the are 2 decimal places
			return fixedPrice
		},
		image() {
			return (this.product?.image || this.product?.import_image) || false
		},
		isMinimumQuantity() {
			return this.quantity <= 1
		},
		...mapGetters('modal', [ 'activeModalId' ]),
		...mapGetters('cart', [ 'cart' ]),
		itemIsAboutToBeDeleted() {
			return this.cart?.itemToRemove.items?.[0]?.name === this.product.name &&
			this.cart?.itemToRemove.items?.[0]?.size === this.product.size &&
			this.activeModalId === DELETE_FROM_CART_MODAL
		}
	},
	watch: {
		product() {
			this.quantity = this.product.quantity
		},
		activeModalId() {
			if (this.itemIsAboutToBeDeleted) {
				this.wiggleWidIt = true
			} else {
				this.wiggleWidIt = false
			}
		}
	},
	methods: {
		...mapActions('cart', [
			'removeFromCart',
			'updateCart',
			'stageItemToRemove'
		]),
		...mapMutations('modal', [ 'showModal' ]),
		formatUnit(unit) {
			return formatUnit(unit)
		},
		formatPrice(price) {
			if (isNaN(price)) {
				price = parseFloat(price)
			}
			if (price % 1 != 0) {
				const n = parseFloat(price).toFixed(2)
				return n
			} else {
				return parseInt(price)
			}
		},
		async handleUpdateQuantity(quantityAdjustment) {
			this.isUpdating = true
			const { carts } = this.$apollo.provider.defaultClient.readQuery({
				query: GetCarts
			})
			let response
			const cartIndex = carts.findIndex(cart => parseInt(cart.listing?.id) === parseInt(this.listingId))
			const cartCopy = { ...carts[cartIndex] }
			if (quantityAdjustment === 'increment') {
				this.quantity++
				cartCopy.subTotal += parseInt(this.cartProduct.items[0]?.price)
				response = await this.updateCart(this.cartProduct)
			} else if (quantityAdjustment === 'decrement' && !this.isMinimumQuantity) {
				this.quantity--
				cartCopy.subTotal -= parseInt(this.cartProduct.items[0]?.price)
				response = await this.updateCart(this.cartProduct)
			}
			const itemIndex = cartCopy.items.findIndex(item => parseInt(item.productId) === parseInt(this.product.product_id) && parseInt(item.size) === parseInt(this.product.size))
			const itemCopy = {
				...cartCopy.items[itemIndex],
				quantity: this.quantity
			}
			cartCopy.items.splice(itemIndex, 1, itemCopy)
			carts.splice(cartIndex, 1, {
				...cartCopy,
				numItems: response?.data?.results?.num_items,
				tax: response?.data?.results?.tax,
				total: response?.data?.results?.total,
				subTotal: response?.data?.results?.subtotal
			})
			this.$apollo.provider.defaultClient.writeQuery({
				query: GetCarts,
				variables: {
					listingId: this.listingId
				},
				data: {
					carts: carts
				}
			})
			this.isUpdating = false
		},
		async handleRemoveFromCart() {
			if (this.isCheckout) {
				this.isUpdating = true
				try {
					await this.removeFromCart(this.cartProduct)
				} catch (e) {
					logError(e)
				}
				this.isUpdating = false
			} else {
				this.stageItemToRemove(this.cartProduct)
				this.showModal(DELETE_FROM_CART_MODAL)
			}
		}
	}
}
</script>
